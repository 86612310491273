// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-grandmas-couch-js": () => import("./../src/pages/grandmasCouch.js" /* webpackChunkName: "component---src-pages-grandmas-couch-js" */),
  "component---src-pages-hold-js": () => import("./../src/pages/hold.js" /* webpackChunkName: "component---src-pages-hold-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-juana-collab-js": () => import("./../src/pages/juanaCollab.js" /* webpackChunkName: "component---src-pages-juana-collab-js" */),
  "component---src-pages-juana-in-quarantine-js": () => import("./../src/pages/juanaInQuarantine.js" /* webpackChunkName: "component---src-pages-juana-in-quarantine-js" */),
  "component---src-pages-juana-js": () => import("./../src/pages/juana.js" /* webpackChunkName: "component---src-pages-juana-js" */),
  "component---src-pages-juana-part-one-c-js": () => import("./../src/pages/juanaPartOneC.js" /* webpackChunkName: "component---src-pages-juana-part-one-c-js" */),
  "component---src-pages-juana-part-one-js": () => import("./../src/pages/juanaPartOne.js" /* webpackChunkName: "component---src-pages-juana-part-one-js" */),
  "component---src-pages-press-js": () => import("./../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-space-vegan-js": () => import("./../src/pages/spaceVegan.js" /* webpackChunkName: "component---src-pages-space-vegan-js" */),
  "component---src-pages-supima-js": () => import("./../src/pages/supima.js" /* webpackChunkName: "component---src-pages-supima-js" */)
}

